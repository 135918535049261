import Link from 'next/link'

const LogoSmall = ({
  width = 18,
  height = 25,
  style = null,
  mainLogo = false,
  onClick = null,
}) => {

  const icon = (
    <svg onClick={onClick} style={{ ...style }} width={width} height={height} viewBox='0 0 18 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5.91083 4C6.99363 4 7.82165 3.17355 7.82165 2.01653C7.82165 0.826445 6.99363 0 5.91083 0C4.82802 0 4 0.826445 4 2.01653C4 3.17355 4.82802 4 5.91083 4ZM12.0892 4C13.172 4 14 3.17355 14 2.01653C14 0.826445 13.172 0 12.0892 0C11.0064 0 10.1783 0.826445 10.1783 2.01653C10.1783 3.17355 11.0064 4 12.0892 4Z' fill='#061226'/>
      <path d='M8.98418 25C14.2355 25 18 21.2486 18 15.9835C18 10.7185 14.2355 7 8.98418 7C3.79613 7 0 10.7185 0 15.9835C0 21.2486 3.79613 25 8.98418 25ZM8.98418 21.479C6.13708 21.479 3.98594 19.34 3.98594 15.9835C3.98594 12.6271 6.13708 10.4881 8.98418 10.4881C11.8629 10.4881 14.0141 12.6271 14.0141 15.9835C14.0141 19.34 11.8629 21.479 8.98418 21.479Z' fill='#FFD600'/>
    </svg>
  )

  return mainLogo ? <Link href='/'>{icon}</Link> : icon
}

export default LogoSmall
